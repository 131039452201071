<script src="../../assets/language/en.js"></script>
<template>
    <div>
        <div class="SecondTitleRegistration">
            <!-- if secondTitle check which tips show -->
            <span v-if=" this.$route.params.secondTitle == true">
                {{this.$t("registration.noUserSecondTitle")}}11
            </span>
            <span v-else>
                {{this.$t("registration.secondTitle")}}11
            </span>
        </div>
        <div class="tipsRegistration">
            <span>
                {{ $t("registration.tips") }}
            </span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form :onChange="saveInfo()">
                <van-field
                        required
                        v-model="info.title"
                >
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item
                                    v-model="info.title"
                                    :title="info.title == '' ? $t('registration.formValueTitle'): info.title"
                                    :options="titleOption"/>
                        </van-dropdown-menu>
                    </template>
                </van-field>
                <van-field
                        required
                        v-model="info.firstName"
                        :placeholder="this.$t('registration.formValueFirstName')"
                        :error-message="errMessageFirstName"
                        @blur="validatorFirstName"
                />
                <van-field
                        required
                        v-model="info.lastName"
                        :placeholder="this.$t('registration.formValueLastName')"
                        :error-message="errMessageLastName"
                        @blur="validatorLastName"
                />
                <van-field :label="this.$t('registration.formValueDateOfBirth')" required>
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item
                                    v-model="info.birth.day"
                                    :title="info.birth.day == '' ? $t('registration.formValueDay'): info.birth.day"
                                    :options="dayOption"/>
                            <van-dropdown-item
                                    v-model="info.birth.month"
                                    :title="info.birth.month == '' ? $t('registration.formValueMonth'): info.birth.month"
                                    :options="monthOption"/>
                            <!-- <van-datetime-picker
                                v-model="info.birth.year"
                                type="year"

                            /> -->
                            <van-dropdown-item
                                    v-model="info.birth.year"
                                    :title="info.birth.year == '' ? $t('registration.formValueYear'): info.birth.year"
                                    :options="yearOption"/>
                        </van-dropdown-menu>
                    </template>
                </van-field>
                <van-field v-model="info.mobile" disabled :placeholder="this.$t('registration.formValueMobileNumber')"/>
                <van-field
                        v-model="info.email"
                        :placeholder="this.$t('registration.formValueMobileEmail')"
                        :error-message="errMessageEmail"
                        @blur="validatorEmail"/>

                <van-field required :label="this.$t('registration.formValueMobileGender')">
                    <template #input>
                        <van-radio-group v-model="info.gender" direction="horizontal">
                            <van-radio name="F">{{ $t("registration.formValueMobileGenderFemale") }}</van-radio>
                            <van-radio name="M">{{ $t("registration.formValueMobileGenderMale") }}</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>

                <!-- Statement Under Gender -->
                <div class="second-tips">
                    <div>
                        <input  class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions1"/>
                    </div>
                    <div class="agreeStatement">
                        {{ $t("registration.statementUnderGender1") }}{{ $t("registration.brandName") }}{{ $t("registration.possessive") }} 
                        <!-- TODO： Switch link url according to hostname -->
                        <a v-if="this.brandName=='bobbibrown'" href="https://www.bobbibrown.com.sg/privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='esteelauder'" href="https://www.esteelauder.com.sg/privacy" >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='lamer'" href="https://www.lamer.com.sg/customer-service-privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='origins'" href="https://www.origins.com.sg/customer-service-privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else-if="this.brandName=='labseries'" href="https://www.labseries.com.sg/privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                        <a v-else href="https://www.esteelauder.com.sg/privacy" >{{ $t("registration.privacyPolicy") }}</a>
                        {{ $t("registration.period") }}
                    </div>
                </div>

                <!-- Subscribe all statement -->
                <div class="second-tips">
                    <!-- <div>
                        <input  class="agreeCheckBox" type="checkbox" v-model="info.agreeSubscribeStatement"/>
                    </div> -->
                    <div style="margin-left:28px" class="agreeStatement">
                        <span>
                            {{ $t("registration.statementSubscribe1") }}Estée Lauder
                            {{ $t("registration.statementSubscribe2") }}{{ $t("registration.brandName") }},
                            {{$t("registration.statementSubscribe3") }}

                            <!-- TODO: Switch link url according to hostname -->
                            <a v-if="this.brandName=='bobbibrown'" href="https://www.bobbibrown.com.sg/privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                            <a v-else-if="this.brandName=='esteelauder'" href="https://www.esteelauder.com.sg/privacy" >{{ $t("registration.privacyPolicy") }}</a>
                            <a v-else-if="this.brandName=='lamer'" href="https://www.lamer.com.sg/customer-service-privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                            <a v-else-if="this.brandName=='origins'" href="https://www.origins.com.sg/customer-service-privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                            <a v-else-if="this.brandName=='labseries'" href="https://www.labseries.com.sg/privacy-policy" >{{ $t("registration.privacyPolicy") }}</a>
                            <a v-else href="https://www.esteelauder.com.sg/privacy" >{{ $t("registration.privacyPolicy") }}</a>
                            {{$t("registration.statementSubscribe4") }}
                        </span>
                    </div>
                </div>



                <!-- Channel Selected -->
                <div style="margin-top: 20px; margin-left: 6%; font-size: 14px; font-family: Helvetica; font-weight: 400">
                    <van-checkbox v-model="info.subscribeAll" @click="allClick()" shape="square">
                        {{ $t("registration.subscribeAll") }}
                    </van-checkbox>
                    <div style="margin-left: 10%">
                        <p style="margin: 15px 0px;">
                            {{ $t("registration.marketiong") }}
                        </p>
                        <van-checkbox style="margin-top: 15px" v-model="info.subscribeEmail" shape="square">{{ $t("registration.marketiongEmail") }}</van-checkbox>
                        <van-checkbox style="margin-top: 15px" v-model="info.subscribeMessage" shape="square">{{ $t("registration.marketiongText") }}</van-checkbox>
                        <van-checkbox style="margin-top: 15px" v-model="info.subscribeCall" shape="square">{{ $t("registration.marketiongPhone") }}</van-checkbox>
                    </div>
                </div>


                <!-- Statement button  -->
                <div class="second-tips">
                    <div>
                        <input  class="agreeCheckBox" type="checkbox" v-model="info.agreeConditions"/>
                    </div>
                    <div class="agreeStatement">
                            {{ $t("registration.statementButton1") }}{{ $t("registration.loyaltyProgram") }}{{ $t("registration.statementButton2") }}
                            {{ $t("registration.loyaltyProgram")}}{{ $t("registration.possessive1") }}

                            <!-- TODO: Switch terms conditions according to hostname -->
                            <a  v-if="this.brandName=='bobbibrown'" href="https://www.bobbibrown.com.sg/terms-conditions-loyalty " >{{ $t("registration.termsAndConditions") }}</a>
                            <a  v-else-if="this.brandName=='esteelauder'" href="https://www.esteelauder.com.sg/terms-conditions-loyalty " >{{ $t("registration.termsAndConditions") }}</a>
                            <a  v-else-if="this.brandName=='lamer'" href="https://www.lamer.com.sg/loyalty/terms-conditions" >{{ $t("registration.termsAndConditions") }}</a>
                            <a  v-else-if="this.brandName=='origins'" href="https://www.origins.com.sg/terms-conditions-loyalty" >{{ $t("registration.termsAndConditions") }}</a>
                            <a  v-else-if="this.brandName=='labseries'" href="https://www.labseries.com.sg/terms-conditions-loyalty " >{{ $t("registration.termsAndConditions") }}</a>
                            <a  v-else  href="https://www.esteelauder.com.sg/terms-conditions-loyalty " >{{ $t("registration.termsAndConditions") }}</a>
                            
                    </div>

                </div>


            
                <div style="margin-top: 20px">
                    <van-button
                            :disabled="!(info.agreeConditions && info.title!='' && info.firstName.trim()!='' && info.lastName.trim() 
                            && info.birth.day!='' && info.birth.month!='' && info.birth.month!='' && info.gender!='' 
                            && validatorEmail()  && info.agreeConditions1)"
                            size="large"
                            round
                            class="button-submit"
                            @click="submit()"
                    >
                        {{ $t("registration.submit") }}
                    </van-button>
                </div>
            </van-form>
        </van-cell-group>
        <!-- <van-dialog v-model="show">
            <iframe src="https://www.esteelauder.com.sg/privacy-policy" />
        </van-dialog> -->
    </div>
</template>

<script>
    import {
        Button, Cell, CellGroup, DropdownItem, DropdownMenu,
        Field, Switch, Checkbox, CheckboxGroup, Form, Radio, RadioGroup, DatetimePicker,Popup, Dialog
    } from 'vant';
    import {relateAddOrUpdate} from '@/api/eformApi';

    export default {
        name: "Registration",
        components: {
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Form.name]: Form,
            [CellGroup.name]: CellGroup,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Switch.name]: Switch,
            [Cell.name]: Cell,
            [DropdownItem.name]: DropdownItem,
            [DropdownMenu.name]: DropdownMenu,
            [Button.name]: Button,
            [Field.name]: Field,
            [Dialog.Component.name]: Dialog.Component
    },
        created() {
            // if(!this.$route.params.phoneNumber){
            //     console.log(this.$route.params.phoneNumber)
            //     this.$router.push({
            //         name:'NotFound'
            //     })
            // }
            
            if(localStorage.getItem('info')){
                this.info = JSON.parse(localStorage.getItem('info'))
            }    
            if(sessionStorage.getItem('phoneNumber')){
                this.info.mobile = sessionStorage.getItem('phoneNumber').slice(2)
            }else{
                this.$router.push({
                    name:'NotFound'
                })
            }        
            this.getYear()
            this.checkBrandName()
        },
        data() {
            return {
                show: true,
                firstName: '',
                info: {
                    title: '',
                    firstName: '',
                    lastName: '',
                    birth: {
                        day: '',
                        month: '',
                        year: ''
                    },
                    mobile: '',
                    email: '',
                    gender: '',
                    subscribeAll: false,
                    subscribeEmail: false,
                    subscribeMessage: false,
                    subscribeCall: false,
                    agreeConditions: false,
                    agreeSubscribeStatement:false,
                    agreeCondition1:false,
                    noneUser: this.$route.params.secondTitle
                },
                titleOption: [
                    { text: 'Ms.', value: 'Ms.' },
                    { text: 'Mr.', value: 'Mr.' },
                    { text: 'Doc.', value: 'Unspecific' }
                ],
                dayOption: [
                    { text: '1', value: '1' },
                    { text: '2', value: '2' },
                    { text: '3', value: '3' },
                    { text: '4', value: '4' },
                    { text: '5', value: '5' },
                    { text: '6', value: '6' },
                    { text: '7', value: '7' },
                    { text: '8', value: '8' },
                    { text: '9', value: '9' },
                    { text: '10', value: '10' },
                    { text: '11', value: '11' },
                    { text: '12', value: '12' },
                    { text: '13', value: '13' },
                    { text: '14', value: '14' },
                    { text: '15', value: '15' },
                    { text: '16', value: '16' },
                    { text: '17', value: '17' },
                    { text: '18', value: '18' },
                    { text: '19', value: '19' },
                    { text: '20', value: '20' },
                    { text: '21', value: '21' },
                    { text: '22', value: '22' },
                    { text: '23', value: '23' },
                    { text: '24', value: '24' },
                    { text: '25', value: '25' },
                    { text: '26', value: '26' },
                    { text: '27', value: '27' },
                    { text: '28', value: '28' },
                    { text: '29', value: '29' },
                    { text: '30', value: '30' },
                    { text: '31', value: '31' }
                ],
                monthOption: [
                    { text: '1', value: '1' },
                    { text: '2', value: '2' },
                    { text: '3', value: '3' },
                    { text: '4', value: '4' },
                    { text: '5', value: '5' },
                    { text: '6', value: '6' },
                    { text: '7', value: '7' },
                    { text: '8', value: '8' },
                    { text: '9', value: '9' },
                    { text: '10', value: '10' },
                    { text: '11', value: '11' },
                    { text: '12', value: '12' }
                ],
                yearOption: [
                ],
                firstNameParten: /^((13[0-9])|(17[0-1,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/,
                errMessageFirstName: '',
                errMessageLastName: '',
                errMessageEmail: '',
                secondTitlehidden: false,
                brandName:''
            };
        },
        methods: {
            
            getYear(){
                var year = new Date().getFullYear()-18;
                for(var i=0;i<100;i++){
                    this.yearOption.push({text: (year-i).toString(), value: (year-i).toString()})
                }
            },
            validatorFirstName() {
                if(this.info.firstName=='' || this.info.firstName.trim()=='' ){
                    this.errMessageFirstName = 'not correct';
                }else{
                    this.errMessageFirstName = ''
                }
            },
            validatorLastName() {
                if(this.info.lastName=='' || this.info.lastName.trim()=='' ){
                    this.errMessageLastName = 'not correct';
                }else{
                    this.errMessageLastName = ''
                }
            },
            validatorEmail(){
                const reg = /^$|^[a-zA-Z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
                if (!reg.test(this.info.email)) {
                    this.errMessageEmail = 'Invalid email address ';
                    return false;
                } else {
                    this.errMessageEmail = '';
                    return true;
                }
            },
            submit() {
                console.log(this.info)
                Dialog.confirm({
                    message: this.$t('registration.continue'),
                    confirmButtonText: this.$t('registration.oK'),
                    cancelButtonText: this.$t('registration.cancle'),
                    confirmButtonColor: '#6bb16b',
                }).then(() => {
                    relateAddOrUpdate(
                        {
                            country:localStorage.getItem('countryPath'),
                            linkToken:sessionStorage.getItem('link_token'),
                            brandName: this.brandName
                        },
                        this.info
                    ).then(res => {
                        if (res.success) {
                            this.$router.push({
                                name: 'LinkConsumer'
                            })
                        } else {
                            if(res.data){
                                Dialog.alert({
                                    message: res.data,
                                    confirmButtonText: 'confirm'
                                })
                            }else{
                                Dialog.alert({
                                    message: "System Error, Please try again later.",
                                    confirmButtonText: 'confirm'
                                })
                            }
                        }
                    })
                    // this.$router.push('LinkConsumer')
                }).catch(() => {})
            },

            saveInfo(){
                localStorage.setItem('info',JSON.stringify(this.info))

            },
            allClick() {
                this.info.subscribeEmail = this.info.subscribeAll
                this.info.subscribeMessage = this.info.subscribeAll
                this.info.subscribeCall = this.info.subscribeAll
            },
            checkBrandName(){
                let brandList = ['esteelauder','bobbibrown','lamer','labseries','origins']
                this.brandName = brandList.includes(location.hostname.split('.')[1])?location.hostname.split('.')[1]:'origins'
                // this.brandName = 'lamer'
                console.log(this.brandName)
            },
        },
        watch: {
            'info.subscribeCall': function() {
                if (!this.info.subscribeCall) {
                    this.info.subscribeAll = false
                }
                if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage) {
                    this.info.subscribeAll = true
                }
            },
            'info.subscribeEmail': function() {
                if (!this.info.subscribeEmail) {
                    this.info.subscribeAll = false
                }
                if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage) {
                    this.info.subscribeAll = true
                }
            },
            'info.subscribeMessage': function() {
                if (!this.info.subscribeMessage) {
                    this.info.subscribeAll = false
                }
                if (this.info.subscribeCall && this.info.subscribeEmail && this.info.subscribeMessage) {
                    this.info.subscribeAll = true
                }
            }
        }
    }
</script>

<style scoped>
    input.agreeCheckBox {
        transform : scale(1.5);
        margin-top: 33%
        
    }
    .agreeStatement {
        margin-left:10px;
    }
    .second-tips{
        display: inline-flex;
        font-size: 14px;
        font-family: Helvetica;
        font-weight: 400;
        margin-top: 20px;
        line-height: 20px;
    }
    .button-submit {
        background: black;
        color: white;
        border-radius: 7px;
        margin-top: 10px;
    }
    /*下拉框样式*/
    >>> .van-dropdown-menu {
        width: 100%;
    }
    /*>>> .van-dropdown-menu__title {*/
    /*    position: unset;*/
    /*    color: #afafaf;*/
    /*}*/
    >>> .van-dropdown-menu__item {
        justify-content: left;
    }
    >>> .van-dropdown-menu__bar {
        height: unset;
        box-shadow: unset;
    }
    >>> .van-dialog {
        border-radius: 5px;
    }
    /*>>> .van-popup--top {*/
    /*    width: 90%;*/
    /*    margin: 0 5%;*/
    /*}*/
    /*>>> .van-dropdown-item__option {*/
    /*    margin-left: 10px;*/
    /*}*/
    >>> .van-checkbox{
        /* margin-top: -10% */
    }
</style>
